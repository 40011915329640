<template>
    <div class="desktopLayoutClass">
        <header class="headingContainer">
            <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/back_button.svg" style="cursor: pointer;"
                @click="takeMeBack()" />
            <h3>Nurse Profile</h3>
        </header>
        <div v-if="loader">
            <v-skeleton-loader class="mx-auto" elevation="0" max-width="400"
                type="table-heading, list-item-two-line, image, table-tfoot" />
        </div>
        <div v-else>
            <div style="background-color: #fff; height: 20rem; width: 100%;">
                <span v-if="nurseImages.length <= 0">Profile Picture Not Found</span>
                <v-carousel hide-delimiters :continuous="true" :cycle="true" :show-arrows="false" height="auto">
                    <div style="height: fit-content;" v-for="nurseImage in nurseImages" :key="nurseImage">
                        <v-carousel-item>
                            <div class="d-flex justify-center align-center">
                                <img class="imageTag rounded-lg" :src="nurseImage" alt="Image not found">
                            </div>
                        </v-carousel-item>
                    </div>
                </v-carousel>
            </div>
            <div class="nurseDetailsContainer">
                <div class="nurseContainer">
                    <v-chip label color="rgba(20, 103, 191, 0.10)"
                        style="color: #1467BF !important; font-size: 14px; font-weight: 600;">{{ nurseDetails.nurse_display_type
                        }}</v-chip>
                    <div style="text-align: left;">
                        <h3>{{ nurseDetails.nurse_name }}</h3>
                        <span>{{ nurseDetails.gender }}</span>
                    </div>
                    <div style="display:  flex" v-if="false">
                        <v-rating v-model="ratings">
                            <template #item="{ index }">
                                <img :src="index < ratings ? activeStarImageUrl : defaultStarImageUrl">
                            </template>
                        </v-rating>
                        <span style="margin: 4px 8px; font-size: 16px; font-weight: 600;">(15 reviews)</span>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap: 8px;"
                    v-if="false">
                    <h4>Overall Stats</h4>
                    <div class="overAllStatsParentContainer">
                        <div class="overStatDetailsContainer">
                            <h3>15+ </h3>
                            <span>Years Of Exp</span>
                        </div>
                        <div class="overStatDetailsContainer">
                            <h3>122+ </h3>
                            <span>No. Of Patients</span>
                        </div>
                        <div class="overStatDetailsContainer">
                            <h3>12+ </h3>
                            <span>Reviews</span>
                        </div>
                    </div>
                </div>
                <div style="text-align: start;">
                    <h4 style="margin-bottom: 8px;">About</h4>
                    <span style="font-size: 14px;">
                        {{ isFullDescriptionShown ? nurseDescription : trimmedDescription }}
                        <span v-if="!isFullDescriptionShown" style="font-size: 14px;">...
                            <button style="color:#48ACEF" @click="toggleText">Read More</button>
                        </span>
                    </span>
                </div>
                <div style="text-align: left;" v-if="expertiseList.length > 0">
                    <h4>Area of Expertise</h4>
                    <div style="display: flex; flex-wrap: wrap; align-items: center; ">
                        <v-chip label color="#F4F6FA" style="color:#4F4F4F; margin: 8px 8px 8px 0;"
                            v-for="(expertise, index) of nurseSpecialtyTrimmed" :key="index">
                            {{ expertise }}
                        </v-chip>
                        <span v-if="!showAllExpertiseList" style="font-size: 14px; font-weight: 600;">
                            <button style="color:#48ACEF" @click="showAllExpertiseList = true">View All</button>
                        </span>
                    </div>
                </div>
                <div style="text-align: left" v-if="false">
                    <h4>Reviews</h4>
                    <div v-for="(review, index) of reviews" :key="index"
                        style="border-bottom: 1px solid #E0E0E0; padding-bottom: 10px; margin-top: 21px;">
                        <div style="display: flex; margin: 10px 0;">
                            <img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/commonNurseIcon.svg" />
                            <div style="margin-left: 10px;">
                                <h5 style="color: #333; font-size: 12px;">Jeevan</h5>
                                <div style="display:  flex; margin-top: 2px;">
                                    <v-rating v-model="ratings">
                                        <template #item="{ index }">
                                            <img style="height: 16px; width: 16px; margin: 0px 2px;"
                                                :src="index < ratings ? activeStarImageUrl : defaultStarImageUrl">
                                        </template>
                                    </v-rating>
                                </div>
                            </div>
                        </div>
                        <span>Vitamin E is an essential nutrient that functions as an antioxidant in the human body.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { axios_auth_instance_nurseBooking } from '../../utils/axios_utils';
import { mixinFunctions } from '../../mixins/index';
export default {
    data() {
        return {
            nurseImages: [],
            activeStarImageUrl: "https://s3iconimages.mymedicine.com.mm/adminNurseActiveStarIcon.svg",
            defaultStarImageUrl: "https://s3iconimages.mymedicine.com.mm/adminNurseDefaultStarIcon.svg",
            ratings: 3,
            expertiseList: ['Basic Nursing Care', 'Basic Care', ' Nursing Care'],
            nurseDescription: '',
            isFullDescriptionShown: false,
            reviews: [{}, {}, {}],
            showAllExpertiseList: false,
            nurseDetails: {},
            loader: true
        }
    },
    mixins:[mixinFunctions],
    methods: {
        toggleText() {
            this.isFullDescriptionShown = !this.isFullDescriptionShown;
        },
        takeMeBack() {
            this.$router.go(-1);
        }
    },
    async mounted() {
        try {
            this.loader = true
            let fetchNurseDetails = await axios_auth_instance_nurseBooking.get(`/pagewise/nurseDetails?nurseID=${this.$route.params.nurseID}`);
            this.nurseDetails = fetchNurseDetails.data.data
            let primaryImage = fetchNurseDetails.data.profileImages.primaryImage
            let secondaryImages = fetchNurseDetails.data.profileImages.secondaryImages
            this.nurseImages.push(primaryImage)
            this.nurseImages.push(...secondaryImages)
            this.ratings = parseInt(this.nurseDetails.avg_rating)
            this.nurseDescription = this.nurseDetails.about
            this.expertiseList = fetchNurseDetails.data.data.detailed_experience
            if (this.expertiseList.length < 5) {
                this.showAllExpertiseList = true;
            }
            this.loader = false
        } catch (error) {
            console.log(error.response)
            if (error.response.status === 401) {
                this.$cookies.remove('customerToken');
                this.$router.push({
                    name: "Login",
                });
            }
        }
        this.userPageViewTable('NBANurseDetailsPage');
    },
    computed: {
        trimmedDescription() {
            if (this.nurseDescription.split(' ').length < 20) {
                this.isFullDescriptionShown = true
            }
            return this.nurseDescription.split(' ').slice(0, 20).join(' ') + '...';
        },
        nurseSpecialtyTrimmed() {
            return this.showAllExpertiseList ? this.expertiseList : this.expertiseList.slice(0, 5);
        },
    },
}
</script>
<style scoped>
.imageTag {
    width: 100%;
    height: auto;
}

span {
    color: #828282 !important;
    font-size: 12px;
}

.nurseDetailsContainer {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    border-radius: 32px 32px 0px 0px;
    margin-top: -40px;
    z-index: 1000;
    position: relative;
    background-color: #fff;
    width: 100%;
    border: solid 1px #E0E0E0;
}

.nurseContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 30px;
}

.overStatDetailsContainer {
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    width: 113px
}

.overAllStatsParentContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.headingContainer {
    display: flex;
    width: 100%;
    padding: 12px 20px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #E0E0E0 !important;
    background-color: #fff !important;
}</style>